import React, {useState} from 'react';
import {Card, Container, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import {useNavigate} from "react-router";
import {observer} from "mobx-react-lite";
import {ADMIN_ROUTE} from "../utils/consts";
import {message} from "antd";

const Auth = observer(() => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const click = async () => {
        try{
            if (email === process.env.REACT_APP_ADMIN_LOGIN && password === process.env.REACT_APP_ADMIN_PASSWORD) {
                navigate(ADMIN_ROUTE)
            }
            else {
                message.error('Неправильный логин или пароль')
            }

        } catch (e) {
            alert(e.response.data.message)
        }


    }

    return (
        <Container
            className="d-flex justify-content-center align-items-center"
            style={{height: window.innerHeight - 20}}
        >
            <Card
                style={{width: 600}}
                className="p-5"
            >
                <h2 className="m-auto">Вход
                </h2>
                <Form
                    className="d-flex flex-column"
                >
                    <Form.Control
                        className="mt-3"
                        placeholder="Введите ваш e-mail..."
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <Form.Control
                        className="mt-3"
                        placeholder="Введите ваш пароль..."
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                    />
                    <Row
                        className="d-flex justify-content-between mt-3"
                    >
                        <Button
                            variant="outline-success"
                            onClick={click}
                        >
                            Войти
                        </Button>
                    </Row>
                </Form>
            </Card>
        </Container>
    );
});

export default Auth;