import React from 'react';
import {Routes, Route, Navigate,} from 'react-router'
import {publicRoutes} from "../routes";
import {MAP_ROUTE} from "../utils/consts";
const AppRouter = () => {

    return (
        <Routes>
            {publicRoutes.map(({path, element}) =>
                <Route key={path} path={path} element={element} exact />
            )}
            <Route path='*' element={<Navigate to={MAP_ROUTE}/>}/>
        </Routes>
    );
};

export default AppRouter;
