import {$host} from "./index";

export const fetchCorpuses = async () => {
    const {data} = await $host.get('api/corpus/getcorpus')
    return data
}

export const fetchMap = async () => {
    const {data} = await $host.get('api/map/get')
    return data
}

export const fetchOneMap = async (id) => {
    const {data} = await $host.get('api/map/' + id)
    return data
}

export const fetchOneCorpus = async (id) => {
    const {data} = await $host.get('api/corpus/' + id)
    return data
}

export const updateMap = async (id, map) => {
    const {data} = await $host.put('api/map/update/' + id, map)
    return data
}