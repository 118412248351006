import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Form,} from "react-bootstrap";
import {Context} from "../index";
import Button from "react-bootstrap/Button";
import {fetchCorpuses, fetchMap, fetchOneCorpus, fetchOneMap, updateMap} from "../http/MapAPI";
import {observer} from "mobx-react-lite";
import CorpusList from "../components/CorpusList";
import Row from "react-bootstrap/Row";
import ClassroomList from "../components/ClassroomList";


const Update = observer(() => {
    const {map} = useContext(Context)
    const [classroom, setClassroom] = useState('')
    const [corpusId, setCorpusId] = useState('')
    const [description, setDescription] = useState('')
    const [floor, setFloor] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [url, setUrl] = useState('')
    const [lessonTime, setLessonTime] = useState('')
    const [lunch, setLunch] = useState('')
    const [classroomType, setClassroomType] = useState('')
    const [files, setFiles] = useState('')
    const [field1, setField1] = useState('')

    const handleUpdateMap = async () => {
        try {
            const UpdateMap = await updateMap(map.selectedMap.id, {
                classroom: classroom,
                corpusId: corpusId,
                description: description,
                floor: floor,
                email: email,
                phone: phone,
                url: url,
                lessonTime: lessonTime,
                lunch: lunch,
                classroomType: classroomType,
                field1: field1,
            })
            console.log('Map update complete:', UpdateMap)
        }
        catch (error){
            console.error('Map update error', error)
        }
    }

    let valueCorpus = map.selectedCorpus.number
    let valueClassroom = map.selectedMap.classroom
    console.log(valueCorpus)
    console.log(valueClassroom)
    console.log(map.selectedMap.id)
    const [fetchedMap, setFetchedMap] = useState({});
    const [corpus, setCorpus] = useState({});


    useEffect(() => {
        fetchCorpuses().then(data => map.setCorpus(data))
        fetchMap().then(data => map.setMap(data))
        const fetchCorpus = async () => {
            const corpuses = await fetchCorpuses();
            const selectedCorpus = corpuses.find(corpus => corpus.number === valueCorpus);
            if (selectedCorpus) {
                const data = await fetchOneCorpus(selectedCorpus.id);
                setCorpus(data);
            }
        };

        fetchCorpus();
    }, [valueCorpus])


    useEffect(() => {
        const fetchData = async () => {
                try {
                    const maps = await fetchMap();
                    const selectedMap = maps.find(map => map.corpusId === valueCorpus && map.classroom === valueClassroom);
                    if (selectedMap) {
                        const data = await fetchOneMap(selectedMap.id);
                        setFetchedMap(data);
                    }
                } catch (error) {
                    console.error("Error fetching map data:", error);
                }
        }
        fetchData();
    }, [valueCorpus, valueClassroom]);


    return (
        <Container>
            <Row className="mt-2">
                <Col md="1"></Col>
                <Col md="3" style={{marginLeft: '100px'}}>
                    <CorpusList/>
                    <br/>
                </Col>
                <Col md="5">
                    <Row/>
                    <ClassroomList/>
                    <br/>
                    <Row/>
                </Col>
            </Row>
            <Form>
                <Form.Control
                    value ={map.classroom}
                    onChange={e => setClassroom(e.target.value)}
                    className="mt-3"
                    placeholder="Введите номер аудитории"
                />
                <Form.Control
                    value={map.description}
                    onChange={e => setDescription(e.target.value)}
                    className="mt-3"
                    placeholder="Введите описание аудитории"
                />
                <Form.Control
                    value={map.floor}
                    onChange={e => setFloor(e.target.value)}
                    className="mt-3"
                    placeholder="Введите этаж"
                />
                <Form.Control
                    value={map.corpusId}
                    onChange={e => setCorpusId(e.target.value)}
                    className="mt-3"
                    placeholder="Введите номер корпуса, корпус 6А = 8"
                />
                <Form.Control
                    value={map.email}
                    onChange={e => setEmail(e.target.value)}
                    className="mt-3"
                    placeholder="Введите почту"
                />
                <Form.Control
                    value={map.phone}
                    onChange={e => setPhone(e.target.value)}
                    className="mt-3"
                    placeholder="Введите номер телефона"
                />
                <Form.Control
                    value={map.url}
                    onChange={e => setUrl(e.target.value)}
                    className="mt-3"
                    placeholder="Введите ссылку на сайт"
                />
                <Form.Control
                    value={map.lessonTime}
                    onChange={e => setLessonTime(e.target.value)}
                    className="mt-3"
                    placeholder="Введите время работы"
                />
                <Form.Control
                    value={map.lunch}
                    onChange={e => setLunch(e.target.value)}
                    className="mt-3"
                    placeholder="Введите время обеда"
                />
                <Form.Control
                    value={map.classroomType}
                    onChange={e => setClassroomType(e.target.value)}
                    className="mt-3"
                    placeholder="Введите тип аудитории"
                />
            </Form>
            <Button variant="outline-success" className="mt-3" onClick={handleUpdateMap}>
                Обновить карту
            </Button>
        </Container>
    );
});

export default Update;