import React, { useContext, useMemo, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import Select from 'react-select';

const ClassroomList = observer(() => {
    const { map } = useContext(Context);
    const [selectedOption, setSelectedOption] = useState(null);

    const classroomOptions = useMemo(() => {
        const filteredClassrooms = map.maps.filter(mapes => mapes.corpusId === map.selectedCorpus.number);
        return filteredClassrooms.map(filteredMap => ({
            value: filteredMap,
            label: filteredMap.classroom
        }));
    }, [map.maps, map.selectedCorpus.number]);

    const sortedClassroomOptions = useMemo(() => {
        return [...classroomOptions].sort((a, b) => {
            const getNumericPart = (str) => {
                const matches = str.match(/\d+/);
                if (matches) {
                    return parseInt(matches[0], 10);
                }
                return Infinity;
            };

            const numA = getNumericPart(a.label);
            const numB = getNumericPart(b.label);
            return numA - numB;
        });
    }, [classroomOptions]);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        map.setSelectedMap(selectedOption.value);
    };

    return (
        <Select
            placeholder="Начните вводить номер аудитории"
            options={sortedClassroomOptions}
            onChange={handleChange}
            isSearchable={true}
            value={selectedOption}
        />
    );
});

export default ClassroomList;

