import React, {useContext, useMemo, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import Select from 'react-select';

const DescriptionList = observer(() => {
    const {map} = useContext(Context);
    const [selectedOption, setSelectedOption] = useState(null);

    const options = useMemo(() => {
        const filteredOptions = map.maps
            .filter(mapes => (mapes.description != null && !mapes.description.includes("Учебная")))
            .map(filteredMap => ({
                value: filteredMap, label: filteredMap.description
            }));

        return filteredOptions.slice()
            .sort((a, b) => {
                if (a.label && b.label) {
                    return a.label.localeCompare(b.label);
                }
                return 0;
            });
    });

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        map.setSelectedMap(selectedOption.value);
    };

    return (
        <Select
            placeholder='Начните вводить название подразделения'
            options={options}
            onChange={handleChange}
            isSearchable={true}
            value={selectedOption}
        />
    );
});

export default DescriptionList;
