import {makeAutoObservable} from "mobx";

export default class MapStore {
    constructor() {
        this._map = []
        this._corpuses = []
        this._selectedMap = {}
        this._selectedCorpus = {}
        makeAutoObservable(this)
    }



    setCorpus(corpuses) {
        this._corpuses = corpuses
    }

    setMap(map) {
        this._map = map
    }

    setSelectedMap(map){
        this._selectedMap = map
    }

    setSelectedCorpus(corpus){
        this._selectedCorpus = corpus
    }

    get maps(){
        return this._map
    }


    get corpuses(){
        return this._corpuses
    }

    get selectedCorpus(){
        return this._selectedCorpus
    }

    get selectedMap(){
        return this._selectedMap
    }

}
