import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Image} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {fetchCorpuses, fetchMap, fetchOneCorpus, fetchOneMap} from "../http/MapAPI";
import CorpusList from "../components/CorpusList";
import Row from "react-bootstrap/Row";
import ClassroomList from "../components/ClassroomList";
import DescriptionList from "../components/DescriptionList";
import {Link} from "react-router-dom";

const Map = observer(() => {
    const {map} = useContext(Context)
    let valueCorpus = map.selectedCorpus.number
    let valueClassroom = map.selectedMap.classroom
    let valueDescription = map.selectedMap.description
    console.log(valueDescription)
    console.log(valueCorpus)
    console.log(valueClassroom)
    const [fetchedMap, setFetchedMap] = useState({});
    const [corpus, setCorpus] = useState({});
    const email = fetchedMap.email;
    const description = fetchedMap.description;
    const phone = fetchedMap.phone;
    const url = fetchedMap.url;
    const lessonTime = fetchedMap.lessonTime;
    const lunch = fetchedMap.lunch;


    useEffect(() => {
        const fetchData = async () => {
            if (!valueClassroom)
            try {
                const maps = await fetchMap();
                const selectedMap = maps.find(map => map.corpusId === valueCorpus && map.classroom === valueClassroom);
                if (selectedMap) {
                    const data = await fetchOneMap(selectedMap.id);
                    setFetchedMap(data);
                }
            } catch (error) {
                console.error("Error fetching map data:", error);
            }
            else {
                try {
                    const maps = await fetchMap();
                    const selectedMap = maps.find(map => map.description === valueDescription);
                    if (selectedMap) {
                        const data = await fetchOneMap(selectedMap.id);
                        setFetchedMap(data);
                    }
                } catch (error) {
                    console.error("Error fetching map data:", error);
                }
            }
        };

        fetchData();
    }, [valueCorpus, valueClassroom]);


    useEffect(() => {
        fetchCorpuses().then(data => map.setCorpus(data))
        fetchMap().then(data => map.setMap(data))
        const fetchCorpus = async () => {
                    const corpuses = await fetchCorpuses();
                    const selectedCorpus = corpuses.find(corpus => corpus.number === valueCorpus);
                    if (selectedCorpus) {
                        const data = await fetchOneCorpus(selectedCorpus.id);
                        setCorpus(data);
                    }
        };

        fetchCorpus();
    }, [valueCorpus])

    return (
        <Container>
            <h1 style={{color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                Цифровая карта
            </h1>
            <h1 style={{color: "white", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                РГУ имени С.А. Есенина
            </h1>
            <Row className="mt-2">
                <Col md="1"></Col>
                <Col md="3" style={{marginLeft: '100px'}}>
                <CorpusList/>
                    <br/>
                </Col>
                <Col md="5">
                <Row/>
                <DescriptionList/>
                <br/>
                <Row/>
                { valueCorpus &&
                <ClassroomList/>
                }
                </Col>
            </Row>
            <br/>
            <Row className="justify-content-center">
                {(valueCorpus && !valueClassroom && !valueDescription) &&
            <Image
                src={process.env.REACT_APP_API_URL + corpus.files}>
            </Image>
                }
                { (valueCorpus && !valueClassroom && !valueDescription) &&
                <Row
                    style={{marginLeft: 'auto', color: 'white'}}>
                    Адрес: {corpus.adress}
                    <Row style={{padding: '50px', color: "gray", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Test version 2024 Ryazan State University named after S.A. Esenin
                    </Row>
                </Row>
                }

            </Row>
            <Row
                className="justify-content-center">
                {(valueCorpus && valueClassroom) &&
                    <Image
                        src={process.env.REACT_APP_API_URL + fetchedMap.files}>
                    </Image>
                }
                {(valueDescription && !valueCorpus) &&
                    <Image
                        src={process.env.REACT_APP_API_URL + fetchedMap.files}>
                    </Image>
                }
            </Row>
            <Row style={{marginLeft: 'auto'}}>
            {(valueCorpus && valueClassroom) &&
                <Col style={{color: 'white'}}>
                    { (!url && description) &&
                        <Row>{fetchedMap.description}</Row>
                    }
                    { url &&
                        <Link to={fetchedMap.url} target="_blank">{fetchedMap.description}</Link>
                    }
                    { phone &&
                        <Row>Номер телефона: {fetchedMap.phone}</Row>
                    }
                    {email &&
                        <Row>Почта: {fetchedMap.email}</Row>
                    }
                    {lessonTime &&
                        <Row>Время работы: {fetchedMap.lessonTime} (Обед: {fetchedMap.lunch})</Row>
                    }
                    {/*{lunch &&*/}
                    {/*    <Row style={{marginLeft: 'auto'}}>Обед: {fetchedMap.lunch}</Row>*/}
                    {/*}*/}
                    <Row style={{padding: '50px', color: "gray", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Test version 2024 Ryazan State University named after S.A. Esenin
                    </Row>


                </Col>

            }
            {(valueDescription && !valueCorpus) &&
                <Col style={{color: 'white'}}>
                    { (!url && description) &&
                        <Row>{fetchedMap.description}</Row>
                    }
                    { url &&
                        <Link to={fetchedMap.url} target="_blank">{fetchedMap.description}</Link>
                    }
                    { phone &&
                        <Row>Номер телефона: {fetchedMap.phone}</Row>
                    }
                    {email &&
                        <Row>Почта: {fetchedMap.email}</Row>
                    }
                    {lessonTime &&
                        <Row>Время работы: {fetchedMap.lessonTime} (Обед: {fetchedMap.lunch})</Row>
                    }
                    {/*{lunch &&*/}
                    {/*    <Row style={{marginLeft: 'auto'}}>Обед: {fetchedMap.lunch}</Row>*/}
                    {/*}*/}
                    <Row style={{padding: '50px', color: "gray", display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        Test version 2024 Ryazan State University named after S.A. Esenin
                    </Row>
                </Col>
            }
            </Row>
        </Container>
    );
});

export default Map;
