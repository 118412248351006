import {
    MAP_ROUTE, AUTH_ROUTE, ADMIN_ROUTE,
} from "./utils/consts";

import Map from "./pages/Map";
import Auth from './pages/Auth'
import Update from "./pages/Update";

export const publicRoutes = [

    {
        path: MAP_ROUTE,
        element: <Map />
    },
    {
        path: AUTH_ROUTE,
        element: <Auth />
    },
    {
        path: ADMIN_ROUTE,
        element: <Update />
    },

]
